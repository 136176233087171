<template>
  <div class="user_container">
    <el-row class="user_header">
      <el-col :span="24">{{name}}</el-col>
    </el-row>
    <el-row class="tip">
      <h1>温馨提示：确认个人信息后，请点击下方按钮选择使用的卡号</h1>
    </el-row>
    <div class="user_main">
      <el-table :data="tableData"
                border="">
        <el-table-column label="姓名"
                         prop="name">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="性别"
                         prop="gender">
          <template slot-scope="scope">
            <span>{{ scope.row.sex == 1 ? "男" : "女" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="出生年月"
                         prop="birthday">
          <template slot-scope="scope">
            <span>{{ scope.row.birthday }}</span>
          </template>
        </el-table-column>
        <el-table-column label="诊疗卡号"
                         prop="patCardNo">
          <template slot-scope="scope">
            <span>{{ scope.row.patCardNo }}</span>
          </template>
        </el-table-column>

        <el-table-column label="身份证号"
                         prop="cardNo">
          <template slot-scope="scope">
            <span>{{ scope.row.cardNo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary"
                       @click.native="nav(scope.row)">操作</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-button type="primary"
               @click.native="home"
               class="home">首页</el-button>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'
export default {
  components: {
    Foo
  },
  data () {
    return {
      name: '',
      userCard: "",//卡号或者身份证号
      certNbr: "",//读卡器的身份证
      tableData: [],
    }
  },
  created () {
    this.name = this.$route.query.name
    this.userCard = this.$route.query.userCard
    this.certNbr = this.$route.query.certNbr
    this.getAllUseInfo()

  },
  methods: {
    home () {
      this.$router.push('/home')
    },
    nav (scope) {
      const that = this
      if (that.name === '当天挂号' || that.name === '预约挂号') {
        that.$router.push({
          path: '/register',
          query: {
            name: that.name,
            patName: scope.name,
            cardNo: scope.cardNo,
            patCardNo: scope.patCardNo,
            patCardType: scope.patCardType
          },

        })
      } else if (that.name === '门诊缴费') {
        that.$router.push({
          path: '/outpatient',
          query: {
            name: that.name,
            patName: scope.name,
            patCardNo: scope.patCardNo,
            patCardType: scope.patCardType
          }
        })
      } else if (that.name === '报告打印') {
        that.$router.push({
          path: '/report',
          query: {
            name: that.name,
            patName: scope.name
          }
        })
      } else if (that.name === '住院押金') {
        that.$router.push({
          path: '/inpatient',
          query: {
            name: that.name,
            patName: scope.name
          }
        })
      }
    },
    getAllUseInfo () {
      let _this = this
      let params = {
        cardNo: this.certNbr || this.userCard
        // cardNo: "210604199610111111"
      }
      Promise.all([_this.$api.getAllUseInfo(params)]).then((data) => {
        if (!data[0]) {
          alert("请先建档")
          _this.$router.push({
            path: '/file',

          })
        } else {
          this.tableData = data
        }

      });
    }
  }
}
</script>
<style>
.user_container {
  width: 100%;
  height: 100%;
}
.user_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}
.tip h1 {
  margin-top: 50%;
  font-weight: 400;
  color: red;
  text-align: center;
  font-size: 34px;
}
.user_main {
  width: 90%;
  height: 70%;
  margin: 0 auto;
}
.user_main th {
  font-size: 26px !important;
  background-color: #0359c8 !important;
  text-align: center !important;
  color: white !important;
}
.user_main td {
  font-size: 26px !important;
  color: #0359c8 !important;
  text-align: center !important;
}
.user_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}
.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}
</style>
